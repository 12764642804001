<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Lista de documentacion">
          <div class="d-flex justify-content-between flex-wrap my-2">
            <!-- filter -->
            <b-form-group
              label="Buscador"
              label-cols-sm="3"
              label-align-sm="left"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  placeholder="Buscador"
                  type="search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Limpiar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <b-table
            responsive
            hover
            show-empty
            bordered
            :small="true"
            :items="items"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="filterOn"
            empty-filtered-text="No se encontro ningun resultado"
            empty-text=" "
            sticky-header="400px"
          >
            <template #empty>
              <h4 class="text-center my-2">
                No tiene información para mostrar
              </h4>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
                <strong class="ml-1">Cargando ...</strong>
              </div>
            </template>
            <template #head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template #cell()="data">
              <span class="text-nowrap">{{ data.value }}</span>
            </template>
            <template #cell(acciones)="row">
              <b-button
                variant="gradient-success"
                class="btn-icon mr-50"
                size="sm"
                @click="descargar(row.item)"
              >
                <feather-icon icon="DownloadIcon" />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import documentacionService from '@/service/documentacionService'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      token: localStorage.getItem('token'),
      archivoUsuario: null,
      archivoMetrica: null,
      archivoComision: null,
      required,
      idEmpresa: 0,
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: 'Descripcion',
          key: 'descripcion',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ruta',
          key: 'path',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Fecha publicación',
          key: 'fechaPublicacion',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: ' ',
          key: 'acciones',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      items: [],
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      await this.obtenerDocumentacion()
      this.$store.commit('app/UPDATE_ISLOADING', false)
    })
  },
  methods: {
    async obtenerDocumentacion() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const { data } = await documentacionService.postDocumentacionData(this.token, this.userData.companyid, this.userData.areaid, this.userData.campaign)

      if (data.data.length > 0) {
        this.items = data.data
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    descargar(data) {
      const link = document.createElement('a')
      link.href = data.path
      link.target = '_blank'
      link.download = 'documento'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
}
</script>

<style>

</style>
