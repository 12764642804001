import axios from '@axios'
import store from '@/store'

export default {
  async postDocumentacionData(token, empresa, area, campana) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getDocumentosPublicados`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idEmpresa: empresa,
        idArea: area,
        idCampana: campana,
      },
    })
    return response
  },
}
